import React, { useState } from 'react'
import { Card, Image, Icon, Label, Button, Modal, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Context } from '../Context'
import ErrorMessage from '../ErrorMessage'
import Loader from '../Loader'
import { getUsersByGuid, setPicture, setAvatar, setEmail } from '../api'
import PictureUpload from './PictureUpload'

import moment from 'moment'

const MAX_USERS = 50
const UserInfo = ({ history }) => {
    const { player, setPlayer, setPlayers, game } = React.useContext(Context)
    const {
        authorization,
        isBanned,
        noAds,
        shortId,
        isBanChat,
        isBanNickname,
        isBanPicture,
        botType,
        isPremium,
        avatar,
    } = player
    const isAdmin = authorization === 'admin'
    const isModerator = authorization === 'moderator'

    const isBot = botType === 'default'

    const created = moment(player.createdAt).format('lll')
    const lastAccess = moment(player.lastAccessedAt).format('lll')
    const expires = moment(player.expiresAt).format('lll')
    const [errorMsg, setErrorMsg] = useState(null)
    const [loading, setLoading] = useState(false)

    const [uploadOpen, setUploadOpen] = useState(false) // Manage modal state
    const [doneModalOpen, setDoneModalOpen] = useState(false)

    const [avatarModalOpen, setAvatarModalOpen] = useState(false)
    const [avatarInput, setAvatarInput] = useState('')

    const [emailModalOpen, setEmailModalOpen] = useState(false)
    const [emailInput, setEmailInput] = useState('')

    const overridePolicyPicture =
        player.softBan && player.softBan.picture && player.softBan.picture.receivedByOverride
            ? player.softBan.picture.receivedByOverride
            : null
    const overridePolicyChat =
        player.softBan && player.softBan.chat && player.softBan.chat.receivedByOverride
            ? player.softBan.chat.receivedByOverride
            : null
    const overridePolicyNickname =
        player.softBan && player.softBan.nickname && player.softBan.nickname.receivedByOverride
            ? player.softBan.nickname.receivedByOverride
            : null

    // console.log('PLAYER', player)

    const handleClickUserList = guids => {
        if (guids.length > MAX_USERS) {
            guids = guids.slice(0, MAX_USERS)
        }
        setLoading(true)
        getUsersByGuid(game, guids, (err, players) => {
            setLoading(false)
            if (err) return setErrorMsg(err)
            setPlayers(players)
            history.push('/users')
        })
    }

    const Badge = ({ label, color }) => (
        <Label style={{ float: 'right' }} color={color ? color : 'pink'} horizontal>
            {label}
        </Label>
    )

    const handlePictureUpload = base64Image => {
        // Placeholder for API call to upload the base64 string
        // console.log('Uploading picture:', base64Image)
        setErrorMsg('')
        setLoading(true)
        setPicture(null, null, game, player.guid, base64Image, err => {
            setUploadOpen(false) // Close the modal after submission
            setLoading(false)
            if (err) return setErrorMsg(err)
            setDoneModalOpen(true)
            setErrorMsg('')
        })
    }

    const handleAvatarSubmit = () => {
        setLoading(true)
        setAvatar(null, null, game, player.guid, avatarInput, err => {
            setAvatarModalOpen(false)
            setLoading(false)
            if (err) return setErrorMsg(err)
            setDoneModalOpen(true)
            setErrorMsg('')
        })
    }

    const handleEmailSubmit = () => {
        setLoading(true)
        setEmail(null, null, game, player.guid, emailInput, err => {
            setEmailModalOpen(false)
            setLoading(false)
            if (err) return setErrorMsg(err)
            setDoneModalOpen(true)
            setErrorMsg('')
        })
    }

    const reloadPlayerData = () => {
        setLoading(true);
        getUsersByGuid(game, [player.guid], (err, updatedPlayers) => {
            setLoading(false);
            if (err) return setErrorMsg(err);
            if (updatedPlayers && updatedPlayers.length > 0) {
                // Assicurati di utilizzare il contesto corretto e di aggiornare direttamente il giocatore
                setPlayer(updatedPlayers[0]);
            }
        });
    };

    return (
        <div>
            {loading && <Loader style={{ height: '150%' }} />}
            <Card style={{ maxWidth: '383px' }} centered fluid>
                <Card.Content>
                    <Card.Header>
                        {isAdmin && <Badge label="Admin" color="green" />}
                        {isModerator && <Badge label="Moderator" color="green" />}
                        {isBanned && <Badge label="Banned" />}
                        {noAds && <Badge label="No Ads" color="green" />}

                        {isPremium && <Badge label="Gold" color="yellow" />}

                        {isBot && <Badge label="Bot" color="red" />}

                        {isBanChat && <Badge label="Chat Ban" />}
                        {overridePolicyChat === 'override_policy_false' && (
                            <div>
                                {' '}
                                <Badge color="blue" label="Chat Override False" /> <br />{' '}
                            </div>
                        )}
                        {overridePolicyChat === 'override_policy_true' && (
                            <div>
                                {' '}
                                <Badge color="blue" label="Chat Override True" /> <br />{' '}
                            </div>
                        )}

                        {isBanNickname && <Badge label="Nick Ban" />}
                        {overridePolicyNickname === 'override_policy_false' && (
                            <div>
                                {' '}
                                <Badge color="blue" label="Nick Override False" /> <br />{' '}
                            </div>
                        )}
                        {overridePolicyNickname === 'override_policy_true' && (
                            <div>
                                {' '}
                                <Badge color="blue" label="Nick Override True" /> <br />{' '}
                            </div>
                        )}

                        {isBanPicture && <Badge label="Picture Ban" />}
                        {overridePolicyPicture === 'override_policy_false' && (
                            <div>
                                {' '}
                                <Badge color="blue" label="Picture Override False" /> <br />{' '}
                            </div>
                        )}
                        {overridePolicyPicture === 'override_policy_true' && (
                            <div>
                                {' '}
                                <Badge color="blue" label="Picture Override True" /> <br />{' '}
                            </div>
                        )}

                        {player.nickname}
                    </Card.Header>
                </Card.Content>
                <Image
                    label={{
                        color: 'blue',
                        content: 'Current',
                        icon: 'picture',
                        ribbon: true,
                    }}
                    style={{ padding: '50px' }}
                    src={player.pixUrl}
                    ui={false}
                    centered
                    wrapped
                    size="medium"
                />
                {player.pixUploadedInfo && (
                    <Image
                        label={{
                            color: 'blue',
                            content: `Uploaded ${moment(player.pixUploadedInfo.updatedAt).format(
                                'lll'
                            )}`,
                            icon: 'picture',
                            ribbon: true,
                        }}
                        style={{ padding: '50px' }}
                        src={player.pixUploadedInfo.completeUrl}
                        ui={false}
                        centered
                        wrapped
                        size="medium"
                    />
                )}
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button
                        primary
                        style={{ maxWidth: '150px' }}
                        onClick={() => setUploadOpen(true)}>
                        Upload pix
                    </Button>
                    <Button
                        primary
                        style={{ maxWidth: '150px' }}
                        onClick={() => setAvatarModalOpen(true)}>
                        Set Avatar
                    </Button>
                </div>

                <Card.Content>
                    <Card.Header>
                        {player.game}
                        <br />
                        {player.guid}
                    </Card.Header>
                    <Card.Meta>
                        <br />
                        <div>ShortId {shortId}</div>

                        <div>Created {created}</div>
                        <div>Last Access {lastAccess}</div>
                        <div>Expires {expires}</div>
                        <div>Avatar '{avatar}'</div>
                        <div>
                            Email Login (SIID) '
                            {player.authMechs?.find(authMech => authMech.type === 'siid')?.email}'
                            <Button
                                primary
                                size="tiny"
                                style={{ marginLeft: '10px' }}
                                onClick={() => setEmailModalOpen(true)}>
                                Update
                            </Button>
                        </div>
                    </Card.Meta>
                    <Card.Description>
                        {player.about}
                        <br />
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    {/*eslint-disable-next-line*/}
                    <a
                        onClick={() => {
                            handleClickUserList(player.relationships.friends)
                        }}>
                        <Icon name="user" />
                        {player.relationships
                            ? player.relationships.friends.length
                            : 0} Friends{' '}
                        {player.relationships.friends.length > MAX_USERS
                            ? '(query is capped to 50)'
                            : ''}
                    </a>
                    <br />
                    {/*eslint-disable-next-line*/}
                    <a
                        onClick={() => {
                            handleClickUserList(player.relationships.blocks)
                        }}>
                        <Icon name="user" />
                        {player.relationships ? player.relationships.blocks.length : 0} Blocks
                        {player.relationships.blocks.length > MAX_USERS
                            ? '(query is capped to 50)'
                            : ''}
                    </a>
                    <br />
                    {/*eslint-disable-next-line*/}
                    <a
                        onClick={() => {
                            handleClickUserList(player.relationships.blockedBy)
                        }}>
                        <Icon name="user" />
                        {player.relationships ? player.relationships.blockedBy.length : 0} Blocked
                        By
                        {player.relationships.blockedBy.length > MAX_USERS
                            ? '(query is capped to 50)'
                            : ''}
                    </a>
                    {errorMsg && <ErrorMessage title="Error" body={errorMsg} />}
                </Card.Content>
                <Card.Content extra>
                    <Icon name="group" />
                    <Link to="/user/groups">{player.groups ? player.groups.length : 0} Groups</Link>
                    <br />
                    <Icon name="chess king" />
                    <Link to={`/group/shortId/${player.clan}`}>{player.clan ? 1 : 0} Clan</Link>
                    <br />
                    <Icon name="key" />
                    <Link to="/user/authmechs">
                        {player.authMechs ? player.authMechs.length : 0} Auth Mechs
                    </Link>
                    <br />
                    <Icon name="computer" />
                    <Link to="/user/platforms">
                        {player.platforms ? player.platforms.length : 0} Platforms
                    </Link>
                    <br />
                    <Icon name="mobile" />
                    <Link to="/user/fcm">{player.fcm ? player.fcm.length : 0} FCM</Link>
                    <br />
                    <Icon name="mail" />
                    <Link to="/user/notifications">
                        {player.notifications ? player.notifications.length : 0} notifications
                    </Link>
                    <br />
                    <Icon name="crosshairs" />
                    <Link to={`/user/score-level/${player.guid}`}>Scores</Link>
                    <br />
                    <Icon name="crosshairs" />
                    <Link to={`/user/score-events/${player.guid}`}>Score events</Link>
                    <br />
                    <Icon name="ban" />
                    <Link to={`/user/soft-bans`}>Soft Bans</Link>
                    <br />
                    <Icon name="dollar sign" />
                    <Link to={`/user/balance`}>Balance</Link>
                    <br />
                    <Icon name="briefcase" />
                    <Link to={`/user/collectibles`}>Collectibles</Link>
                    <br />
                    <Icon name="mail" />
                    <Link to={`/user/conversations`}>Conversations</Link>
                    <br />
                    <Icon name="computer" />
                    <Link to={`/user/installations`}>Installations</Link>
                </Card.Content>
            </Card>

            <PictureUpload
                open={uploadOpen}
                onClose={() => setUploadOpen(false)}
                onSubmit={handlePictureUpload}
            />

            <Modal open={avatarModalOpen} onClose={() => setAvatarModalOpen(false)}>
                <Modal.Header>Set Avatar</Modal.Header>
                <Modal.Content>
                    <Input
                        placeholder="Enter new avatar string"
                        value={avatarInput}
                        onChange={e => setAvatarInput(e.target.value)}
                        fluid
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setAvatarModalOpen(false)} secondary>
                        Cancel
                    </Button>
                    <Button onClick={handleAvatarSubmit} primary>
                        Submit
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={emailModalOpen} onClose={() => setEmailModalOpen(false)}>
                <Modal.Header>Set Email</Modal.Header>
                <Modal.Content>
                    <Input
                        placeholder="Enter new email"
                        value={emailInput}
                        onChange={e => setEmailInput(e.target.value)}
                        fluid
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setEmailModalOpen(false)} secondary>
                        Cancel
                    </Button>
                    <Button onClick={handleEmailSubmit} primary>
                        Submit
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal open={doneModalOpen} onClose={() => setDoneModalOpen(false)}>
                <Modal.Header>Success</Modal.Header>
                <Modal.Content>
                    <p>Done.</p>
                    {/* <p>You must reload the user to see the changes in the profile.</p> */}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            setDoneModalOpen(false)
                            reloadPlayerData()
                        }} // Close modal when OK is clicked
                        primary>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default withRouter(UserInfo)
